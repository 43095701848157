import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/index/index.module').then((m) => m.IndexModule),
  },
  {
    path: 'detalhes-do-plano',
    loadChildren: () =>
      import('./views/plan-details/plan-details.module').then(
        (m) => m.PlanDetailsModule
      ),
  },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
